import { useRouter } from "next/router";
import React, { useContext, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";
import useSWR, { useSWRConfig } from "swr";
import { useTranslations } from "next-intl";
import { centraGetProduct } from "../lib/centra-api";
import { StoryContext } from "../pages/[...route]";
import { generateStoryTree, StoryWithChildren } from "../lib/storyblok-api";
import { useCentraToken } from "../lib/utils";
import Link from "./Link";
import CentraProductLink from "./CentraProductLink";
import CentraProductImage from "./CentraProductImage";
import StoryblokImage from "./StoryblokImage";

async function arrayFetcher(
  ids: string[],
  token: string,
  locale: string,
  currency: string
): Promise<CentraProductApiData[]> {
  return await Promise.all(
    ids.map(async (id) =>
      centraGetProduct(`centraproduct://${id}/${locale}/${currency}`, token)
    )
  );
}

export default function MobileMenu() {
  const context = useContext(StoryContext);
  const router = useRouter();
  const [token] = useCentraToken();
  const t = useTranslations("Header");
  const { mutate } = useSWRConfig();
  const currency = context.currency;
  const [activeCat, setActiveCat] = useState<StoryWithChildren>();
  const [activeSubCat, setActiveSubCat] = useState<StoryWithChildren>();
  const [activeLinkGroup, setActiveLinkGroup] = useState<any>();

  // const [searchTerm, setSearchTerm] = useState("");
  // const [searchIsActive, setSearchIsActive] = useState(false);
  // const [productSearchResults, setProductSearchResults] = useState([]);
  // const [categorySearchResults, setCategorySearchResults] = useState([]);
  // const [searchResultsTotal, setSearchResultsTotal] = useState(0);
  // const [loadingSearchResults, setLoadingSearchResults] = useState(false);
  // const [searchSwrFallback, setSearchSwrFallback] = useState({});
  // const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const featuredProductIds: string[] =
    activeCat?.content.featured_product?.productIds;
  const { data: featuredProductsData } = useSWR(
    featuredProductIds?.length ? JSON.stringify(featuredProductIds) : null,
    (ids) => arrayFetcher(JSON.parse(ids), token, router.locale, currency)
  );
  const featuredLinks = activeCat?.content.featured_links;

  useEffect(() => {
    featuredProductsData?.forEach((p) => {
      mutate(
        `centraproduct://${p.product.product}/${router.locale}/${currency}`,
        p,
        false
      );
    });
  }, [featuredProductsData]);

  let letterGroups: { [key: string]: StoryWithChildren[] };

  if (activeCat?.full_slug === "brands") {
    const getFirstLetter = (c) => {
      const firstLetter = (c.content.name || c.name).slice(0, 1);
      return /\p{Letter}/u.test(firstLetter) && firstLetter ? firstLetter : "#";
    };
    const sortedChildren = sortBy(activeCat.children, getFirstLetter);
    letterGroups = groupBy(sortedChildren, getFirstLetter);
  }

  function resolveLink(block) {
    if (block.link.linktype === "story") {
      const resolvedLink = context.mainMenu.links?.find(
        (ll) => ll.uuid === block.link.id
      );

      if (resolvedLink) {
        const linkTree = generateStoryTree(
          context.menuItems,
          resolvedLink.full_slug,
          0
        );

        block.link.story = linkTree[0]?.children
          ? { ...resolvedLink, children: linkTree[0].children }
          : resolvedLink;
      } else {
        block.link.story = { name: "Save to view" };
      }
    }

    return block;
  }

  const headerLinksLeft = useMemo(
    () =>
      context.mainMenu?.content?.header_links_left
        ?.map(resolveLink)
        .filter((b) => b.text || b.link.story?.name),
    [context.mainMenu?.content?.header_links_left]
  );

  const customerServiceLinkGroups =
    context.mainMenu?.content.customer_service_links;

  /* const searchProducts = (term, page = 1) => {
    const query = new URLSearchParams();
    query.set("term", term);
    query.set("locale", router.locale);
    query.set("page", `${page}`);
    const url = new URL("/api/product-search", location.href);
    url.search = query.toString();

    setLoadingSearchResults(true);

    fetch(url.href)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error(`${res.status}`);
        }
      })
      .then((data) => {
        const centraData = data.results.reduce((result, p) => {
          const key = `centraproduct://${p.product}/${router.locale}`;
          result[key] = { product: p };
          return result;
        }, {});

        setSearchIsActive(true);
        setCategorySearchResults(data.categories);
        setProductSearchResults(
          page > 1 ? productSearchResults.concat(data.results) : data.results
        );
        setSearchSwrFallback(Object.assign({}, searchSwrFallback, centraData));
        setSearchResultsTotal(data.totalResults);
        setLoadingSearchResults(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }; */

  /* const loadMoreSearchResults = () => {
    const page = productSearchResults.length / 100 + 1;
    searchProducts(searchTerm, page);
  };

  /* const submitSearch = (event) => {
    event.preventDefault();
    searchProducts(searchTerm);
  };
 */
  /* useEffect(() => {
    if (!debouncedSearchTerm) {
      return;
    }

    searchProducts(debouncedSearchTerm);
  }, [debouncedSearchTerm]); */

  // Hide category navigation panels when submitting search
  /*  useEffect(() => {
     if (searchIsActive) {
       setActiveCat(null);
       setActiveSubCat(null);
       setActiveLinkGroup(null);
     }
   }, [searchIsActive]); */

  /* const searchForm = (
     <form
       className={classNames("MobileMenu-search")}
      //  onSubmit={submitSearch}
     >
       <input
         autoComplete="off"
        //  autoFocus={searchIsActive}
         className="MobileMenu-searchInput"
         name="s"
        //  onChange={(event) => setSearchTerm(event.currentTarget.value)}
         placeholder={t("search.inputLabel")}
         required
        //  value={searchTerm}
       />
 
       {/* {loadingSearchResults ? (
         <Spinner className="MobileMenu-searchSpinner" />
       ) : (
         <button className="MobileMenu-searchButton">
           <i className="Icon Icon--search" />
           <span>{t("search.inputLabel")}</span>
         </button>
       )} 
     </form>
   ); */

  return (
    <section
      className={classNames("MobileMenu", {
        "is-showingSubnav": activeCat,
        // categorySearchResults.length ||
        // productSearchResults.length,
      })}
    >
      {!activeCat &&
      !activeSubCat /* && !searchIsActive */ &&
      !activeLinkGroup ? (
        <div className="MobileMenu-column MobileMenu-column--top">
          {/* {searchForm} */}

          <div className="MobileMenu-catList MobileMenu-catList--topLevel">
            {headerLinksLeft.map((b, i) => (
              <li
                className={classNames("MobileMenu-catListItem", {
                  "is-red": b.is_red,
                })}
                key={b._uid}
                style={{ "--index": 1 + i } as React.CSSProperties}
              >
                {b.link.story?.children ? (
                  <button onClick={() => setActiveCat(b.link.story)}>
                    <span>{b.text || b.link.story?.name}</span>
                  </button>
                ) : (
                  <Link
                    href={
                      b.link.story?.full_slug
                        ? `/${b.link.story.full_slug}`
                        : b.link.url
                    }
                  >
                    <span>{b.text || b.link.story?.name}</span>
                  </Link>
                )}
              </li>
            ))}
          </div>

          <div className="MobileMenu-columnFooter">
            <ul className="MobileMenu-catList MobileMenu-catList--topLevel">
              {customerServiceLinkGroups?.map((b) => (
                <li className="MobileMenu-catListItem" key={b._uid}>
                  <button onClick={() => setActiveLinkGroup(b)}>
                    {b.text}
                  </button>
                </li>
              ))}
            </ul>
            <div className="MobileMenu-columnFooterContact">
              <hr />
              <p className="MobileMenu-catListItem">{t("topMenu.contact")}</p>
              <ul className="MobileMenu-catList">
                <li className="MobileMenu-catListItem">
                  <button
                    onClick={() => {
                      window.open("tel:0046317117621");
                    }}
                  >
                    <span>+46 31-711 76 21</span>
                  </button>
                </li>
                <li className="MobileMenu-catListItem">
                  <Link href={(router.locale === "sv" ? "/sv" : "") + "/customer-service/contact-customer-service"}>{t("contactUs")}</Link>
                </li>
                <li className="MobileMenu-catListItem">
                  <button
                    onClick={() => {
                      window.open("mailto:info@artilleriet.se");
                    }}
                  >
                    <span>info@artilleriet.se</span>
                  </button>
                </li>

                <li className="MobileMenu-catListItem">
                  <Link
                    href={(router.locale === "sv" ? "/sv" : "") + "/customer-service/information/our-stores"}
                    aria-label={t("visitUs")}
                  >
                    {t("visitUs")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      ) : null}

      {activeCat && activeCat.full_slug === "brands" && !activeSubCat ? (
        <div className="MobileMenu-column">
          <button
            className="MobileMenu-backButton"
            onClick={() => setActiveCat(null)}
          >
            <i className="Icon Icon--chevron-left" />
            {t("backButton")}
          </button>

          <ul className="MobileMenu-catList">
            {Object.entries(letterGroups).map(([letter, cats]) => {
              const list = cats.map((m) => (
                <li className="MobileMenu-catListItem" key={m.id}>
                  <Link href={"/" + m.full_slug}>{m.content.name}</Link>
                </li>
              ));

              return (
                <React.Fragment key={letter}>
                  <h4 className="MobileMenu-letterHeading">
                    {letter.toUpperCase()}
                  </h4>

                  {list}
                </React.Fragment>
              );
            })}
          </ul>

          {featuredProductsData?.length && (
            <div className="MobileMenu-columnFooter">
              <div className="MobileMenu-columnFooterProducts">
                {featuredLinks?.map((b) => (
                  <Link
                    className="MobileMenu-featuredLink"
                    key={b._uid}
                    href={
                      b.link.linktype === "story"
                        ? b.link.cached_url
                        : b.link.url
                    }
                  >
                    <StoryblokImage
                      className="MobileMenu-featuredLinkImage"
                      asset={b.image}
                    />
                    <div className="MobileMenu-featuredLinkName">{b.text}</div>
                  </Link>
                ))}

                {featuredProductsData.map((item) => (
                  <CentraProductLink
                    className="MobileMenu-featuredLink"
                    key={item.product.product}
                    product={item.product}
                  >
                    <CentraProductImage
                      className="MobileMenu-featuredLinkImage"
                      src={item.product.media.portrait?.[0]}
                    />
                    <div className="MobileMenu-featuredLinkName">
                      {item.product.name}
                    </div>
                  </CentraProductLink>
                ))}
              </div>
            </div>
          )}
        </div>
      ) : null}

      {activeCat && activeCat.full_slug !== "brands" && !activeSubCat ? (
        <div className="MobileMenu-column">
          <button
            className="MobileMenu-backButton"
            onClick={() => setActiveCat(null)}
          >
            <i className="Icon Icon--chevron-left" />
            {t("backButton")}
          </button>

          <ul className="MobileMenu-catList">
            <li className="MobileMenu-catListGroup">
              <h4 className="MobileMenu-catListItem MobileMenu-catListItem--heading">
                {activeCat.content.name}
              </h4>

              <div className="MobileMenu-catListItem MobileMenu-catListItem--viewAll">
                <Link href={"/" + activeCat.full_slug}>
                  {t("viewAllButton")}
                </Link>
              </div>
            </li>

            {activeCat.children?.map((m) => (
              <li className="MobileMenu-catListItem" key={m.id}>
                {m.children ? (
                  <button onClick={() => setActiveSubCat(m)}>
                    {m.content.name}
                  </button>
                ) : (
                  <Link href={"/" + m.full_slug}>{m.content.name}</Link>
                )}
              </li>
            ))}
          </ul>
        </div>
      ) : null}

      {activeSubCat ? (
        <div className="MobileMenu-column">
          <button
            className="MobileMenu-backButton"
            onClick={() => setActiveSubCat(null)}
          >
            <i className="Icon Icon--c" />
            {t("backButton")}
          </button>

          <ul className="MobileMenu-catList">
            <li className="MobileMenu-catListGroup">
              <h4 className="MobileMenu-catListItem MobileMenu-catListItem--heading">
                {activeSubCat.content.name}
              </h4>

              <div className="MobileMenu-catListItem MobileMenu-catListItem--viewAll">
                <Link href={"/" + activeSubCat.full_slug}>
                  {t("viewAllButton")}
                </Link>
              </div>
            </li>

            {activeSubCat.children?.map((m) => (
              <li className="MobileMenu-catListItem" key={m.id}>
                <Link href={"/" + m.full_slug}>{m.content.name}</Link>
              </li>
            ))}
          </ul>
        </div>
      ) : null}

      {activeLinkGroup ? (
        <div className="MobileMenu-column">
          <button
            className="MobileMenu-backButton"
            onClick={() => setActiveLinkGroup(null)}
          >
            <i className="Icon Icon--c" />
            {t("backButton")}
          </button>

          <ul className="MobileMenu-catList">
            <li className="MobileMenu-catListGroup">
              <h4 className="MobileMenu-catListItem MobileMenu-catListItem--heading">
                {activeLinkGroup.text}
              </h4>
            </li>

            {activeLinkGroup.links?.map((l) => {
              const block = resolveLink(l);

              return (
                <li className="MobileMenu-catListItem" key={block._uid}>
                  <Link
                    href={
                      block.link.story?.full_slug
                        ? `/${block.link.story.full_slug}`
                        : block.link.url
                    }
                  >
                    {block.text || block.link.story?.name}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      ) : null}

      {/* {searchIsActive ? (
        <div className="MobileMenu-column">
          {searchForm}

          <button
            className="MobileMenu-backButton"
            onClick={() => {
              setSearchIsActive(false);
            }}
          >
            <i className="Icon Icon--c" />
            {t("backButton")}
          </button>

          <div className="MobileMenu-viewAllLink">
            {t("search.mobileHeading", { n: searchResultsTotal })}
          </div>

          {categorySearchResults.length ? (
            <>
              <h4 className="MobileMenu-searchResultsHeading">
                {t("search.catsHeading")}
              </h4>

              <ul className="MobileMenu-categorySearchResults">
                {categorySearchResults.map((p) => (
                  <li className="MobileMenu-categorySearchResult" key={p.id}>
                    <Link href={"/" + p.full_slug}>
                      {p.parent_name ? (
                        <>
                          <span>{p.parent_name}</span>
                          <i className="Icon Icon--arrowRight" />
                        </>
                      ) : null}
                      {p.content.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </>
          ) : null} */}

      {/* {productSearchResults.length ? (
            <>
              <h4 className="MobileMenu-searchResultsHeading">
                {t("search.productsHeading")}
              </h4>

              <div className="MobileMenu-searchResults">
                <SWRConfig
                  value={{
                    fallback: searchSwrFallback,
                    revalidateIfStale: false,
                    revalidateOnMount: true,
                  }}
                >
                  {productSearchResults.map((p) => (
                    <ProductGridItem
                      className="ProductGrid-item--small"
                      key={p.product}
                      productId={p.product}
                    />
                  ))}
                </SWRConfig>
              </div>
            </>
          ) : null} */}

      {/* {searchResultsTotal > productSearchResults.length ? (
            <div className="MobileMenu-searchFooter">
              <button
                className="MobileMenu-searchPaginationButton"
                disabled={loadingSearchResults}
                onClick={loadMoreSearchResults}
              >
                {t("search.loadMore")}
              </button>
            </div>
          ) : null}
        </div>
      ) : null} */}
    </section>
  );
}
